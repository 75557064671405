import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_12/slide5';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "slide-4-12-5-mobile"}}) {
      body
    }
    file(relativePath: { eq: "chapter_4/sub_12/mobile/4-12-5-bg.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    audioImage: file(relativePath: { eq: "chapter_3/sub_1/3-1-3-audio__A.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
    hoverDot: mdx(
      frontmatter: {
        language: { eq: "DE" }
        title: { eq: "gb-fr-dots" }
      }
    ) {
      exports {
        hoverInfo {
          positionLeft
          positionTop
          identifier
          insideText
        }
      }
    },
    modal41251:  mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "gb-fr-modals"}, selector: {eq: "modal41251"}}) {
      body
    },
    modal41252:  mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "gb-fr-modals"}, selector: {eq: "modal41252"}}) {
      body
    },
    audio1: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "slide-4-4-1-audio1"}}) {
      excerpt(pruneLength: 100000)
    },
    audio2: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "slide-4-4-1-audio1"}}) {
      excerpt(pruneLength: 100000)
    },
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
